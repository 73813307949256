//The function that actually does the hiding/revealing work
const toggleElement = (target) => {
	const currentHeight = target.getBoundingClientRect().height;
	const isHidden = currentHeight <= 0;
	target.style.maxHeight = isHidden ? target.getAttribute('data-height') : 0;
	target.classList.toggle('target-hidden');
};

// eslint-disable-next-line no-inline-comments
const eyeToggleEmptyStateBox = /*html*/`
	<div id="eyeToggleEmptyStateBox" class="empty">
		<div class="kitchensink text-center">
			<h4 class="--alt --mb-0 --mw-100">Oops! Everything is hidden. Nothing to see here.</h4>
		</div>
	</div>
`;

export default {
	init () {
		//Don't allow clicks before everything's ready
		let debouncing = true;
		const eyeToggles = document.querySelectorAll('.eye-toggle');

		//Sets up each toggle and its targets for clicking to hide/show
		eyeToggles.forEach((toggle) => {
			const input = toggle.querySelector('input[type="checkbox"]');
			const label = toggle.querySelector('label');
			const targetSelector = label.getAttribute('data-target');
			const targets = document.querySelectorAll(targetSelector);

			//Initialize the styles of the target(s) for smooth animation
			targets.forEach((target) => {
				target.setAttribute('data-height', `${target.getBoundingClientRect().height}px`);
				target.classList.add('eye-toggle-target');
				target.style.maxHeight = input.checked ? target.getAttribute('data-height') : 0;

				//Hide items where the eye toggle is off by default
				if (!input.checked) {
					target.classList.toggle('target-hidden');
				}
			});

			toggle.addEventListener('click', (e) => {
				//Only trigger the event once
				if (e.target === input) {

					//Don't allow the user to click too fast and get things into a wonky mismatched state
					if (!debouncing) {
						targets.forEach((target) => {
							toggleElement(target);
						});
						label.setAttribute('aria-expanded', label.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');

						const areAnyTogglesOn = [...eyeToggles].filter((toggle) => toggle.querySelector('input[type="checkbox"]').checked).length > 0;

						if (!areAnyTogglesOn) {
							targets[targets.length - 1].insertAdjacentHTML('afterend', eyeToggleEmptyStateBox);
						} else {
							const toggleStateBox = document.querySelector('#eyeToggleEmptyStateBox');
							if (toggleStateBox) {
								toggleStateBox.remove();
							}
						}
					} else {
						e.preventDefault();
						return;
					}

					//Can only be clicked once every quarter second to prevent syncing issues while animation is still running
					debouncing = true;
					setTimeout(() => {
						debouncing = false;
					}, 250);
				}
			});
		});
		//Ok, now we can allow clicks
		debouncing = false;
	}
};
